export const BG_PRIMARY = '#F2E3E0';
export const BG_SECONDARY = '#7E2826';
export const BG_SECONDARY_LIGHT = '#F3C844';
export const BG_SECONDARY_DARK = '#86550a';
export const BG_SECONDARY_TRANSPARENT = 'rgb(50 15 14 / 49%)';
// this colors used for green background
export const BG_ALTERNATIVE = '#7E2826';
export const TEXT_PRIMARY_LIGHT = '#cf9c2f';
export const TEXT_PRIMARY = '#F3C844';
export const TEXT_PRIMARY_DARK = '#000';
export const TEXT_SECONDARY = '#fff';

// Exclusive to template-naomirezky
export const FLOWER_COLOR = '#7E2826';
